import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Search() {
    const [search, setSearch] = useState(false);


    const searchItems = [
        {
          id: 1,
          name: "Apple iPhone 16 Pro 256GB",
          description: "A powerful and feature-rich smartphone",
          finalPrice: 60000,
          regularPrice: 75000,
          image: "/assets/temp-img/apple-img.png",
          category: "Phones"
        },
        {
          id: 2,
          name: "Headphones Pro Bluetooth",
          description: "Experience immersive sound with our wireless headphones",
          finalPrice: 5560,
          regularPrice: 5560,
          image: "/assets/temp-img/headphones-img.png",
          category: "Headphones"
        },
        {
          id: 3,
          name: "MetroVibe Green Sling Bag",
          description: "A sleek and compact sling bag designed for luxury adventures",
          finalPrice: 4500,
          regularPrice: 4500,
          image: "/assets/temp-img/handbag-img.png",
          category: "Bags"
        },
        {
          id: 4,
          name: "Apple Watch Series 7",
          description: "A sleek and feature-rich smartwatch",
          finalPrice: 40000,
          regularPrice: 50000,
          image: "/assets/temp-img/watch-img.png",
          category: "Watches"
        },

        {
            id: 5,
            name: "Apple iPhone 16 Pro 256GB",
            description: "A powerful and feature-rich smartphone",
            finalPrice: 60000,
            regularPrice: 75000,
            image: "/assets/temp-img/apple-img.png",
            category: "Phones"
          },
          {
            id: 6,
            name: "Headphones Pro Bluetooth",
            description: "Experience immersive sound with our wireless headphones",
            finalPrice: 5560,
            regularPrice: 5560,
            image: "/assets/temp-img/headphones-img.png",
            category: "Headphones"
          },
          {
            id: 7,
            name: "MetroVibe Green Sling Bag",
            description: "A sleek and compact sling bag designed for luxury adventures",
            finalPrice: 4500,
            regularPrice: 4500,
            image: "/assets/temp-img/handbag-img.png",
            category: "Bags"
          },
          {
            id: 8,
            name: "Apple Watch Series 7",
            description: "A sleek and feature-rich smartwatch",
            finalPrice: 40000,
            regularPrice: 50000,
            image: "/assets/temp-img/watch-img.png",
            category: "Watches"
          }
      ];

      const [activeCategory, setActiveTab] = useState("All Products");
      const categories = ["All Products", "Headphones", "Watches", "Phones", "Bags"];
      const handleCategoryClick = (category) => {
        setActiveTab(category);
      };

      const filteredItems = searchItems.filter((item) => {
        if (activeCategory === "All Products") {
          return true;
        } else {
          return item.category === activeCategory;
        }
      });
    
    const toggleSearch = () => {
        setSearch(!search);
    };
    return (
        <div className="relative">
            <button type="button" className={`relative rounded-full bg-white border border-gray-300 w-8 h-8 flex items-center justify-center`}
                 onClick={toggleSearch}>
                <img src="/assets/icons/search.svg" alt="Cart" className="w-3.5" />
            </button>
           

            {search && (
                <div className="fixed sm:absolute top-12 shadow-[0px_1px_10px_rgba(0,0,0,0.25)] end-[3%] md:end-0 w-[92%] sm:w-[36rem] bg-white rounded-lg transition-opacity z-50">
                    <div className="max-h-vh70 flex flex-col overflow-y-auto">
                        <div className='flex justify-between items-center gap-2 p-4 border-b border-theme-borderColor'>
                            <span  onClick={toggleSearch} className='min-w-fit cursor-pointer'>
                                <img src="/assets/icons/left-arrow.svg" alt="" className='size-4' />
                            </span>
                            <input type="text" placeholder="Search..." className="input-box py-0 border-0 w-full" />
                            <span className='min-w-fit cursor-pointer'>
                               <span className='text-xs font-customMedium text-red-600 underline'>Clear</span>
                            </span>
                        </div>

                        <div className='px-4'>
                            <div className="flex gap-2  py-4 pb-3 mb-2 border-b border-theme-borderColor scroll-smooth scrollbar-thin overflow-x-auto">
                                {categories.map((category) => (
                                    <button
                                        key={category}
                                        onClick={() => handleCategoryClick(category)}
                                        className={`px-3 py-1.5 rounded-lg font-customSemiBold text-[12px] flex-shrink-0 ${activeCategory === category
                                            ? "bg-theme-accentColorSecondary text-white"
                                            : "bg-[#f9f9f9] text-theme-headingColor"
                                            }`}
                                    >
                                        {category}
                                    </button>
                                ))}
                            </div>
                        </div>

                        <ul className="p-4 flex-1 overflow-y-auto scroll-style grid grid-cols-1 xs:grid-cols-2 gap-6 ">
                            {filteredItems.map(item => (
                                <li key={item.id} >
                                    <Link to={`/product-details/${item.id}`} className="flex sm:grid grid-cols-7 gap-3 pb-4 border-b border-theme-borderColor"   onClick={toggleSearch}>
                                    <div className="w-[4rem] min-w-[4rem] xs:w-[3rem] flex justify-center items-center xs:min-w-[3rem] sm:w-full sm:min-w-[unset] sm:col-span-2 h-full bg-[#F9F9F9] rounded-lg">
                                        <img src={item.image} alt="" className="aspect-[0.75/1] w-full object-cover rounded-lg bg-[#F9F9F9]" />
                                    </div>
                                    <div className='sm:col-span-5 flex flex-col justify-between'>
                                        <div>
                                            <h5 className="text-theme-headingColor text-ellipsis line-clamp-2 text-[13px] font-customBold">{item.name}</h5>
                                            <p className="text-theme-subHeadingColor text-ellipsis line-clamp-2 text-[11px] font-customRegular">{item.description}</p>
                                        </div>
                                        <h5 className="text-theme-headingColor text-[13px] font-customBold">₹ {item.finalPrice}</h5>
                                    </div>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                        
                    </div>
                </div>
            )}
             {search && (
                <div onClick={toggleSearch} className='bg-overly z-[40] block lg:backdrop-blur-none lg:bg-transparent'> </div>
            )}
        </div>
    )
}

export default Search