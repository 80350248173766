import React from 'react';
import { setPopupStatus, setPopupType, setPopupData } from '../redux/slices/popupModal';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { loginSliderConfig } from '../constants/CarouselConfigs';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Controller, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import apiService from '../utility-functions/Api-Services';
import { useEffect } from 'react';
import { setUserData } from '../redux/slices/userDataSlice';
import usePasswordVisibility from '../hooks/usePasswordVisibility';

const Login = () => {
  const dispatch = useDispatch();
  function doSetupPopupType(status, type = null, data = null ) {
    dispatch(setPopupStatus(status));
    dispatch(setPopupType(type));
    dispatch(setPopupData(data));
  }

  const { register, handleSubmit, formState: { errors, isSubmitted }, reset } = useForm();
  const { passwordVisibility, togglePasswordVisibility } =
    usePasswordVisibility(['password']);
  const onSubmit = (data) => {
 /*  const rememberMeChecked = document.getElementById('remember').checked;
  if (!rememberMeChecked) {
    toast.error("Make sure to check 'Remember Me' before proceeding with the login.");
    return;
  } */
    console.log(data); 
    const url = '/login';
    const getParams = {};
    const postParams = {
      email: data.email,
      password: data.password,
      device_type: "W", 
      device_model: "",
      device_token: "",
      app_version: "",
    };
    apiService.postRequest(url, getParams, postParams)
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.message);
          const data = response.data;
         /*  dispatch(setUserData(data)); */
          reset();
          if(data?.is_verified === 0){
            doSetupPopupType(true, 'otpVerification', data);
          }
          else if(data?.is_verified === 1){ 
            dispatch(setUserData(data))
            doSetupPopupType(false);
          }
        } else {
          toast.error(response.message); 
          reset();
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };
  
  const errorMsgClass = 'text-red-600 text-xs ps-1 font-customRegular';
  const images = [
    {
      id: 1,
      image: "/assets/images/login-banner.svg",
    },
    {
      id: 2,
      image: "/assets/images/login-banner-2.svg",
    }
  ]
  return (
    <div  className="grid relative grid-cols-1 max-h-[calc(90vh-16px)] items-center lg:grid-cols-2 bg-white rounded-xl shadow-lg border overflow-hidden w-full  xs:max-w-[450px] lg:max-w-[1100px] mx-auto p-6 lg:p-3">
      <div className="hidden lg:block w-full max-h-[calc(90vh-40px)] min-h-[75vh] h-full bg-cover rounded-xl">
        <Swiper
          {...loginSliderConfig}
          modules={[Navigation, Pagination, Controller]}
         className='h-full'>
          {images.map((item) => (
            <SwiperSlide key={item.id}>
               <img src={item.image} alt="" className='w-full h-full object-cover rounded-xl' />
            </SwiperSlide>
          ))}
        </Swiper>
        
      </div>

      <div className="w-full  mb-16 lg:px-12 max-h-[calc(90vh-104px)] h-fit overflow-auto lg:pt-8 lg:pb-2 lg:w-11/12 mx-auto flex flex-col justify-between gap-8">
        <div className="text-center">
          <p className="text-2xl md:text-3xl text-theme-headingColor pb-1 font-customBold">
            Sign in to your Account
          </p>
          <p className='text-theme-subHeadingColor text-sm font-customMedium'>
            Don't have an account?{" "}
            <span
              onClick={() => doSetupPopupType(true, "register")}
              className='font-customBold text-theme-accentColorSecondary underline cursor-pointer'
            >
              Sign up
            </span>
          </p>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-1 overflow-y-auto scroll-style flex-col gap-8'>
          <div className='flex flex-col gap-4 flex-1 overflow-y-auto scroll-style '>
            <div >
              <input
                {...register("email", {
                  required: "Please enter your email address.",
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: "Invalid email address",
                  },
                })}
                id="email"
                type="email"
                placeholder="Email"
                className='input-box'
                aria-label="Email"
              />
              {errors.email && (
                <small className={errorMsgClass}>{errors.email.message}</small>
              )}
            </div>

            <div >
              <div className='relative'>
              <input
               type={passwordVisibility.password.isVisible ? 'text' : 'password'}
                {...register("password", {
                  required: "Please enter your password.",
                  minLength: {
                    value: 6,
                    message: "Password must be at least 6 characters long",
                  },
                })}
                id="password"
                placeholder="Password"
                className='input-box'
                aria-label="Password"
              />
              <img
                  src={passwordVisibility.password.icon}
                  alt="password"
                  onClick={() => togglePasswordVisibility('password')}
                  className="password-icon"
                />
              </div>
              {errors.password && (
                <small className={errorMsgClass}>{errors.password.message}</small>
              )}
              <div className='mt-2 flex justify-between items-center'>
                <label htmlFor='remember' className='flex items-center gap-2'>
                  <input name='remember' id='remember' type="checkbox" className='size-[16px] accent-theme-accentColorSecondary' />
                  <p className='text-[13px] text-theme-subHeadingColor font-customMedium block text-end cursor-pointer'>Remember me</p>
                </label>
                <p
                  onClick={() => doSetupPopupType(true, 'forgotPassword')}
                  className="text-[13px] text-theme-accentColorSecondary font-customSemiBold block text-end cursor-pointer"
                >
                  Forgot Password?
                </p>
              </div>
             
            </div>
          </div>
          {/*  onClick={() => doSetupPopupType('otpVerification')} */}

          <button
            type="submit"
            className="theme-button py-3 bg-theme-accentColorSecondary border-theme-accentColorSecondary">
            Login
          </button>
        </form>

       
      </div>

      <div className='text-center  absolute bottom-6 lg:bottom-3 grid grid-cols-1 lg:grid-cols-2 '>
          <p className='text-[13px] font-customMedium lg:col-start-2 px-6 lg:px-12 md:w-4/5 xl:w-3/4 mx-auto text-theme-subHeadingColor'>By signing up, you agree to the <span className='text-theme-accentColorSecondary font-customBold'><Link to="/">Terms of Service</Link></span> and <span className='text-theme-accentColorSecondary font-semibold'><Link to="/">Data Processing Agreement</Link></span></p>
      </div>
    </div>
  );
};

export default Login;
