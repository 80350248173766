import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function MiniCart() {
    const [cartMenu, setCartMenu] = useState(false);
    const [displayDeletePopup, setDisplayDeletePopup] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [cartData, setCartData] = useState([
        {
            id: 1,
            name: "Apple iPhone 16 Pro 256GB",
            description: "Sleek and lightweight, the MacBook features",
            finalPrice: 60000,
            regularPrice: 75000,
            image: "/assets/temp-img/apple-img.png",
            quantity: 1,
        },
        {
            id: 2,
            name: "MetroVibe Green Sling Bag",
            description: "A sleek and compact sling bag designed for luxury adventures",
            finalPrice: 4500,
            regularPrice: 4500,
            image: "/assets/temp-img/handbag-img.png",
            quantity: 2,
        },
    ]);
    const [currentCartCount, setCurrentCartCount] = useState(cartData.length);

    const updateQuantity = (itemId, amount) => {
        setCartData(prevCartData =>
            prevCartData.map(item =>
                item.id === itemId
                    ? { ...item, quantity: Math.max(1, item.quantity + amount) } // Ensure quantity is at least 1
                    : item
            )
        );
    };

    const removeFromCart = (product) => {
        setCartData(cartData.filter(item => item.id !== product.id));
        setCurrentCartCount(currentCartCount - 1);
        toggleDeletePopup(product);
        if (currentCartCount === 1) {
            setCartMenu(false);
        }
    };

    const toggleCartMenu = () => {
        setCartMenu(!cartMenu);
    };

    const toggleDeletePopup = (item) => {
        setSelectedItem(item);
        setDisplayDeletePopup(!displayDeletePopup);
        document.body.style.overflow = displayDeletePopup ? "auto" : "hidden";
    };

    return (
        <div className="relative">
            {currentCartCount > 0 && (
                <span className="absolute -top-2 z-[5] leading-tight -end-2 size-5 flex items-center justify-center text-xs text-white bg-theme-accentColorSecondary rounded-full">
                    <span className='text-[10px] lg:text-xs font-customMedium'>{currentCartCount}</span>
                </span>
            )}
            <button type="button" className={`${currentCartCount >= 1 ? "" : "pointer-events-none"} relative rounded-full bg-white border border-gray-300 w-8 h-8 flex items-center justify-center`} onClick={toggleCartMenu}>
                <img src="/assets/icons/shopping-bag.svg" alt="Cart" className="w-3.5" />
            </button>

            {cartMenu && (
                <div className="fixed sm:absolute top-12 shadow-[0px_1px_10px_rgba(0,0,0,0.25)] end-[3%] md:end-0 w-[92%] sm:w-80 bg-white rounded-lg transition-opacity z-50">
                    <div className="max-h-vh70 flex flex-col overflow-y-auto">
                        <div className='flex justify-between items-center gap-2 p-4 border-b border-theme-borderColor'>
                            <p className='text-base font-customBold text-black '>Cart: {currentCartCount} items</p>
                            <span onClick={toggleCartMenu} className='size-6 rounded-full flex justify-center shadow-[0px_1px_10px_rgba(0,0,0,0.25)] items-center bg-white cursor-pointer'>
                                <img src="/assets/icons/close-gray.svg" alt="" className='size-2' />
                            </span>
                        </div>

                        <ul className="p-4 flex-1 overflow-y-auto scroll-style flex flex-col gap-4 ">
                            {cartData.map(item => (
                                <li key={item.id} className="grid last:border-b-0 last:pb-0 grid-cols-4 gap-3 pb-4 border-b border-theme-borderColor">
                                    <div className="w-full h-full bg-[#F9F9F9] rounded-lg">
                                        <img src={item.image} alt="" className="aspect-[0.75/1] w-full object-cover rounded-lg bg-[#F9F9F9]" />
                                    </div>
                                    <div className='col-span-3 flex flex-col justify-between'>
                                        <div className='flex justify-between items-start gap-2'>
                                            <div>
                                                <h5 className="text-theme-headingColor text-ellipsis line-clamp-2 text-sm font-customBold">{item.name}</h5>
                                                <p className="text-theme-subHeadingColor text-ellipsis line-clamp-2 text-xs font-customRegular">{item.description}</p>
                                            </div>
                                            <span onClick={() => toggleDeletePopup(item)} className='min-w-fit cursor-pointer'>
                                                <img src="/assets/icons/close-gray.svg" alt="" className='size-2.5 mt-1' />
                                            </span>
                                        </div>
                                        <div className='flex justify-between items-end gap-2'>
                                            <div>
                                                <h5 className="text-theme-headingColor text-sm font-customBold">₹ {item.finalPrice}</h5>
                                            </div>
                                            <span className='min-w-fit flex gap-2 items-center border border-theme-borderColor px-2 py-1 rounded-lg'>
                                                <span onClick={() => updateQuantity(item.id, -1)} className='cursor-pointer'>
                                                    <img src="/assets/icons/minus.svg" alt="" className='size-3 brightness-0'/>
                                                </span>
                                                <span className='px-2 text-xs font-customSemiBold text-black'>{item.quantity}</span>
                                                <span onClick={() => updateQuantity(item.id, 1)} className='cursor-pointer'>
                                                    <img src="/assets/icons/plus.svg" alt="" className='size-3 brightness-0'/>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <div className='p-4 flex flex-col gap-3 border-t border-theme-borderColor'>
                            <div className='flex justify-between items-center gap-3'>
                                <div className='flex items-center gap-1'>
                                    <p className='text-black text-sm font-customSemiBold'>Total:</p>
                                    <p className='text-theme-subHeadingColor font-customMedium text-sm'>
                                        ({currentCartCount} items)
                                    </p>
                                </div>
                                <p className='text-black text-sm font-customBold'>₹ 60,000</p>
                            </div>
                            <Link to="/checkout">
                                <button onClick={toggleCartMenu}
                                    className="w-full py-2.5 font-customSemiBold bg-theme-accentColorSecondary text-white rounded-lg text-sm">
                                    Proceed to Checkout
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            )}

            {/* Delete confirmation popup */}
            {displayDeletePopup && (
                <div className="popup-css z-[52] max-w-[350px]">
                    <span onClick={() => toggleDeletePopup(null)} className="closeBtn bg-theme-accentColorSecondary text-white"> <img src="/assets/icons/close-white.svg" alt="" className='size-3' /></span>
                    <div className="bg-white p-6 rounded-md text-center">
                        <p className="text-gray-600 mb-4">Are you sure you want to delete this package?</p>
                        <div className="flex gap-4 justify-center">
                            <button onClick={() => toggleDeletePopup(null)} className="w-full py-2.5 font-customSemiBold border border-theme-accentColorSecondary bg-white text-theme-accentColor rounded-lg text-sm">
                                Cancel
                            </button>
                            <button onClick={() => removeFromCart(selectedItem)} className="w-full py-2.5 font-customSemiBold bg-theme-accentColorSecondary text-white rounded-lg text-sm">
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {displayDeletePopup && (
                <div onClick={() => toggleDeletePopup(null)} className='bg-overly z-[51]'> </div>
            )}
            {cartMenu && (
                <div onClick={toggleCartMenu} className='bg-overly z-[40] block lg:backdrop-blur-none lg:bg-transparent'> </div>
            )}
        </div>
    );
};

export default MiniCart