export const  bannerCarousalConfig = {
  slidesPerView: 1,
  slidesPerGroup: 1,
  loop: false,
  pagination: { clickable: true },
  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },
  lazy: { enabled: true },
};

export const categorySliderConfig = {
  slidesPerView: 2,
  slidesPerGroup: 2,
  spaceBetween: 12,
  loop: false,
  lazy: { enabled: true },
  breakpoints: {
    0: {
      spaceBetween: 12,
      slidesPerView: 2,
    },
    640: {
      spaceBetween: 12,
      slidesPerView: 2,
    },
    768: {
      spaceBetween: 20,
      slidesPerView: 3,
    },
    1024: {
      spaceBetween: 20,
      slidesPerView: 4,
    },
    1280: {
      spaceBetween: 20,
      slidesPerView: 5,
    },
  },
};


export const productSliderConfig = {
    slidesPerView: 2,
    slidesPerGroup: 1,
    spaceBetween: 12,
    loop: false,
    lazy: { enabled: true },
    breakpoints: {
      0: {
        spaceBetween: 12,
        slidesPerView: 2,
      },
      640: {
        spaceBetween: 12,
        slidesPerView: 2,
      },
      768: {
        spaceBetween: 20,
        slidesPerView: 3,
      },
      1024: {
        spaceBetween: 20,
        slidesPerView: 4,
      },
      1280: {
        spaceBetween: 20,
        slidesPerView: 4,
      },
    },
  };
  



  export const detailsSliderConfig = {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 12,
    loop: false,
    lazy: { enabled: true },
    breakpoints: {
      0: {
        spaceBetween: 12,
        slidesPerView: 1,
      },
    },
  };

  export const detailThumbSliderConfig = {
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 12,
    loop: false,
    lazy: { enabled: true },
    breakpoints: {
      0: {
        spaceBetween: 12,
        slidesPerView: 3,
      },
      640: {
        spaceBetween: 12,
        slidesPerView: 4,
      },
      768: {
        spaceBetween: 20,
        slidesPerView: 5,
      },
      1024: {
        spaceBetween: 20,
        slidesPerView: 6,
      },
      1280: {
        spaceBetween: 20,
        slidesPerView: 6,
      },
    },
  };


  export const loginSliderConfig = {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 12,
    loop: false,
    lazy: { enabled: true },
    pagination: {
      clickable: true, // Enables pagination bullets to be clickable
    },
    breakpoints: {
      0: {
        spaceBetween: 12,
        slidesPerView: 1,
      },
    },
  }