const useNameInitials = () => {
  const convertToInitials = (name) => {
      const fullName = name.split(' ');
      const firstNameLetter = fullName[0]?.charAt(0).toUpperCase() || '';
      const lastNameLetter = fullName[1]?.charAt(0).toUpperCase() || '';
      
      return firstNameLetter + lastNameLetter;
  };

  return { convertToInitials };
};

export default useNameInitials;
