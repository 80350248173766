import { configureStore } from '@reduxjs/toolkit';
import userDataReducer from './slices/userDataSlice';
import currentLoginFlowPopupReducer from './slices/currentLoginFlowPopupSlice';
import addressFlowPopupReducer from './slices/addressFlowPopupSlice';
import popupModalReducer from './slices/popupModal';

const store = configureStore({
  reducer: {
    userData: userDataReducer,
    popupModal:popupModalReducer,
/*     currentLoginFlowPopup: currentLoginFlowPopupReducer,
    addressFlowPopup: addressFlowPopupReducer */
  },
});

export default store;
