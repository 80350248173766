import React from 'react'
import { setPopupStatus, setPopupType } from '../redux/slices/popupModal';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { loginSliderConfig } from '../constants/CarouselConfigs';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Controller, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import apiService from '../utility-functions/Api-Services';
import toast from 'react-hot-toast';
import { setUserData } from '../redux/slices/userDataSlice';

const ForgotPassword = () => {

  const dispatch = useDispatch();
  function doSetupPopupType(status, type = null) {
    dispatch(setPopupStatus(status));
    dispatch(setPopupType(type));
  }

  const { register, handleSubmit, formState: { errors, isSubmitted }, reset } = useForm();

  const onSubmit = async (data) => {
    if (isSubmitted) {
      const getParams = {};
      const postParams = {
        email: data.email,
      };
      try {
        const response = await apiService.postRequest(
          "/forgot-password",
          getParams,
          postParams
        );
        if (response.status === 200) {
          toast.success(response.message);
          const data = response.data;
          dispatch(setUserData(data));
          doSetupPopupType(false);
        } else {
          toast.error(response.message);
        }
      } catch (err) {
        toast.error(err);
      } finally {
        reset();
      }
    }
  };


 /*  function doSetupPopupType(value) {
    dispatch(setPopupType(value));
  } */

  
  const errorMsgClass = 'text-red-600 text-xs ps-1 font-customRegular';

  const images = [
    {
      id: 1,
      image: "/assets/images/login-banner.svg",
    },
    {
      id: 2,
      image: "/assets/images/login-banner-2.svg",
    }
  ]

  return (
    <div className="grid relative grid-cols-1 max-h-[calc(90vh-16px)] items-center lg:grid-cols-2 bg-white rounded-xl shadow-lg border overflow-hidden w-full  xs:max-w-[450px] lg:max-w-[1100px] mx-auto p-6 lg:p-3">
     <div className="hidden lg:block w-full max-h-[calc(90vh-40px)] min-h-[75vh] h-full bg-cover rounded-xl">
        <Swiper
          {...loginSliderConfig}
          modules={[Navigation, Pagination, Controller]}
         className='h-full'>
          {images.map((item) => (
            <SwiperSlide key={item.id}>
               <img src={item.image} alt="" className='w-full h-full object-cover rounded-xl' />
            </SwiperSlide>
          ))}
        </Swiper>
          
      </div>

      <div className="w-full mb-16 lg:px-12 max-h-[calc(90vh-104px)] h-fit overflow-auto lg:pt-8 lg:pb-2 lg:w-11/12 mx-auto flex flex-col justify-between gap-8">
        <div className="text-center">
          <p className="text-2xl md:text-3xl  text-theme-headingColor pb-1 font-customBold">
            Forgot Your Password?
          </p>
          <p className="text-theme-subHeadingColor text-sm font-customMedium">
            Enter your email below and we will send you instructions to reset your password
          </p>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <input
              {...register("email", {
                required: "Please enter your email address.",
                pattern: {
                  value: /^\S+@\S+$/i,
                  message: "Invalid email address",
                },
              })}
              id="email"
              type="email"
              placeholder="Email"
              className='input-box'
              aria-label="Email"
            />
            {errors.email && (
              <small className={errorMsgClass}>{errors.email.message}</small>
            )}
          </div>
          <p className="text-[13px] text-theme-subHeadingColor hover:text-gray-900 mt-2 block text-start cursor-pointer" >
            Remember your password?  <span onClick={() => doSetupPopupType(true, 'login')} className='font-customBold text-theme-accentColorSecondary underline cursor-pointer'>Log In</span>
          </p>

          <button
            type="submit"
            className="theme-button mt-8 py-3 bg-theme-accentColorSecondary border-theme-accentColorSecondary">
            Next
          </button>

        </form>
      </div>
      <div className='text-center  absolute bottom-6 lg:bottom-3 grid grid-cols-1 lg:grid-cols-2 '>
          <p className='text-[13px] font-customMedium lg:col-start-2 px-6 lg:px-12 md:w-4/5 xl:w-3/4 mx-auto text-theme-subHeadingColor'>By signing up, you agree to the <span className='text-theme-accentColorSecondary font-customBold'><Link to="/">Terms of Service</Link></span> and <span className='text-theme-accentColorSecondary font-semibold'><Link to="/">Data Processing Agreement</Link></span></p>
      </div>
    </div>
  )
}

export default ForgotPassword