// Header.js
import React, { useState } from 'react';
import { json, Link, NavLink } from 'react-router-dom';
import Modal from '../components/Modal';
import Login from '../components/Login';
import Register from '../components/Register';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import ForgotPassword from '../components/ForgotPassword';
import OTPVerification from '../components/Otp';
import MiniCart from './MiniCart';
import { setPopupStatus, setPopupType } from '../redux/slices/popupModal';
import Search from './Search';
import useNameInitials from '../hooks/useNameInitials';

const Header = () => {
  const { convertToInitials } = useNameInitials();

  const userData = useSelector((state) => state?.userData?.data, shallowEqual);
  
 
  

  const name = convertToInitials(userData?.first_name + ' ' + userData?.last_name);
  // const name = convertToInitials('Jhon Doe');

  const dispatch = useDispatch();

  function doSetupPopupType(status, type = null) {
    dispatch(setPopupType(type));
    dispatch(setPopupStatus(status));
  }

  const currentLoginFlowPopupType = useSelector(
    (state) => state.popupModal.type
  );

  return (
    <>
      <header className="text-theme-headingColor sticky top-0 w-full inset-x-0 bg-white z-[1024]  shadow py-2 px-5 md:px-8 xl:px-20">
        <div className="mx-auto justify-between items-center  flex">
          <div className='flex items-center gap-5 lg:gap-8'>
            <Link to="/">
              <div className="size-14">
                <img
                  src="/assets/icons/adbis-logo.svg"
                  alt="adbis-logo"
                  className="size-14"
                />
              </div>
            </Link>
            <nav className='hidden md:flex'>
              <ul className="flex gap-5 lg:gap-8">
                <li>
                  <NavLink
                    to="/"
                    className={({ isActive }) =>
                      (isActive
                        ? "text-theme-accentColorSecondary"
                        : "text-theme-headingColor") +
                      " font-customMedium text-[13px] lg:text-[15px]"
                    }
                  >
                    Home
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink
                    to="/wallet"
                    className={({ isActive }) =>
                      (isActive
                        ? "text-theme-accentColorSecondary"
                        : "text-theme-headingColor") +
                      " font-customMedium text-[13px] lg:text-[15px]"
                    }
                  >
                    Wallet (Rs. 55,000)
                  </NavLink>
                </li> */}

                <li>
                  <NavLink
                    to="/listing"
                    className={({ isActive }) =>
                      (isActive
                        ? "text-theme-accentColorSecondary"
                        : "text-theme-headingColor") +
                      " font-customMedium text-[13px] lg:text-[15px]"
                    }
                  >
                    Products
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink
                    to="/referral"
                    className={({ isActive }) =>
                      (isActive
                        ? "text-theme-accentColorSecondary"
                        : "text-theme-headingColor") +
                      " font-customMedium text-[13px] lg:text-[15px]"
                    }
                  >
                    Referral
                  </NavLink>
                </li> */}
              </ul>
            </nav>
          </div>
          <nav>
            <ul className="flex gap-3 items-center">
              <li className='hidden md:flex'>
                <div className='rounded-lg border border-theme-borderColor p-1.5 px-3' >
                  <p className='text-theme-subHeadingColor font-customMedium text-[13px] lg:text-[15px]'>Total Earnings: <span className='font-customSemiBold text-black'>Rs. 5,500</span></p>
                </div>
              </li>
              <li>
                <div className='border border-theme-borderColor size-7 lg:size-8  rounded-full flex justify-center items-center'>
                  <Search />
                </div>
              </li>
              <li>
                <div className='border border-theme-borderColor size-7 lg:size-8  rounded-full flex justify-center items-center'>
                  <MiniCart />
                </div>
              </li>
              <li className='hidden md:flex'>
                    
                    
                {userData?.id ? (
                  <Link to="/my-account/edit-profile">
                    <div className='bg-theme-accentColorSecondary size-7 lg:size-8 rounded-full flex justify-center items-center cursor-pointer'>
                      <p className='text-white pb-0.5 text-xs lg:text-[13px]'>{name}</p>
                    </div>
                  </Link>
                ) : (
                  <div onClick={() => doSetupPopupType(true, 'login')} className='bg-theme-accentColorSecondary size-7 lg:size-8 rounded-full flex justify-center items-center cursor-pointer'>
                    <p className='text-white pb-0.5 text-xs lg:text-[13px]'>{name}</p>
                  </div>
                )}
              </li>

            </ul>
          </nav>
        </div>

        {/* <div className='flex justify-between items-center md:hidden'>
          <Link to="/">
            <div className="size-14">
              <img src="/assets/icons/adbis-logo.svg" alt="adbis-logo" />
            </div>
          </Link>

          <div>
            <div className="border border-theme-borderColor size-7 lg:size-8  rounded-full flex justify-center items-center">
              <MiniCart />
            </div>
          </div>
        </div> */}

        <ul className="px-5 pt-2.5 pb-3.5 shadow-[0px_-1px_10px_rgba(0,0,0,0.15)] bg-white w-full fixed gap-1 inset-x-0 -bottom-1 flex justify-between items-center md:hidden">
          <li>
            <NavLink
              to="/"
              className={({ isActive }) =>
                (isActive
                  ? "text-theme-accentColorSecondary active-route"
                  : "text-[#99A1AC]") +
                " font-customMedium duration-300 ease-in-out text-[12px] lg:text-[15px] flex flex-col items-center gap-0.5"
              }
            >
              <img
                src="/assets/icons/home-icon.svg"
                alt=""
                className="size-5 duration-300 ease-in-out"
              />
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/wallet"
              className={({ isActive }) =>
                (isActive
                  ? "text-theme-accentColorSecondary active-route"
                  : "text-[#99A1AC]") +
                " font-customMedium duration-300 ease-in-out text-[12px] lg:text-[15px] flex flex-col items-center gap-0.5"
              }
            >
              <img
                src="/assets/icons/wallet-gray.svg"
                alt=""
                className="size-5 duration-300 ease-in-out"
              />
              Wallet
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/listing"
              className={({ isActive }) =>
                (isActive
                  ? "text-theme-accentColorSecondary active-route"
                  : "text-[#99A1AC]") +
                " font-customMedium duration-300 ease-in-out text-[12px] lg:text-[15px] flex flex-col items-center gap-0.5"
              }
            >
              <img
                src="/assets/icons/product-gray.svg"
                alt=""
                className="size-5 duration-300 ease-in-out"
              />
              Products
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/referral"
              className={({ isActive }) =>
                (isActive
                  ? "text-theme-accentColorSecondary active-route"
                  : "text-[#99A1AC]") +
                " font-customMedium duration-300 ease-in-out text-[12px] lg:text-[15px] flex flex-col items-center gap-0.5"
              }
            >
              <img
                src="/assets/icons/referral.svg"
                alt=""
                className="size-5 duration-300 ease-in-out"
              />
              Referral
            </NavLink>
          </li>
          <li>
            {userData?.id ? (
              
              <NavLink
              to="/my-account/sidebar"
              className={
                "text-[#99A1AC] font-customMedium text-[12px] lg:text-[15px] flex flex-col duration-300 ease-in-out items-center gap-0.5"
              }
            >
              <img
                src="/assets/icons/account-gray.svg"
                alt=""
                className="size-5 duration-300 ease-in-out"
              />
              Account
            </NavLink>
            ) : (
              <NavLink
              onClick={() => doSetupPopupType(true, "login")}
              className={
                "text-[#99A1AC] font-customMedium text-[12px] lg:text-[15px] flex flex-col duration-300 ease-in-out items-center gap-0.5"
              }
            >
              <img
                src="/assets/icons/account-gray.svg"
                alt=""
                className="size-5 duration-300 ease-in-out"
              />
              Account
            </NavLink>
            )}
            
          </li>
        </ul>
      </header>

      {currentLoginFlowPopupType === "login" && (
        <Modal>
          {" "}
          <Login />{" "}
        </Modal>
      )}
      {currentLoginFlowPopupType === "register" && (
        <Modal>
          {" "}
          <Register />{" "}
        </Modal>
      )}
      {currentLoginFlowPopupType === "forgotPassword" && (
        <Modal>
          {" "}
          <ForgotPassword />{" "}
        </Modal>
      )}
      {currentLoginFlowPopupType === "otpVerification" && (
        <Modal>
          {" "}
          <OTPVerification />{" "}
        </Modal>
      )}
    </>
  );
};

export default Header;
