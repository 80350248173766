import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: JSON.parse(localStorage.getItem('userData')) || {},
  isLoggedIn: JSON.parse(localStorage.getItem('userData')) !== null,
};

const userDataSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.data = action.payload;
      localStorage.setItem('userData', JSON.stringify(action.payload));
      state.isLoggedIn = true;
    },
    clearUserData: (state) => {
      state.data = {};
      state.isLoggedIn = false;
    },
    
  },
});

export const { setUserData, clearUserData } = userDataSlice.actions;
export default userDataSlice.reducer;