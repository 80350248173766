import React, { useState, useEffect } from 'react';
import { setPopupStatus, setPopupType } from '../redux/slices/popupModal';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { loginSliderConfig } from '../constants/CarouselConfigs';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Controller, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import apiService from '../utility-functions/Api-Services';
import toast from "react-hot-toast";
import { setUserData } from '../redux/slices/userDataSlice';


const OTPVerification = () => {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [timer, setTimer] = useState(59);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const userData = useSelector((state) => state.popupModal.data, shallowEqual);

  function doSetupPopupType(status, type = null) {
    dispatch(setPopupType(type));
    dispatch(setPopupStatus(status));
  }

  const clearOtp = () => {
    setOtp(["", "", "", "", "", ""]); // Reset OTP to empty boxes
  };

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/^\d$/.test(value)) {
      const newOtp = [...otp];
      const firstEmptyIndex = newOtp.findIndex((digit) => digit === "");
      if (firstEmptyIndex !== -1 && firstEmptyIndex < index) {
        newOtp[firstEmptyIndex] = value;
        setOtp(newOtp);
        const nextIndex = newOtp.findIndex((digit) => digit === "");
        if (nextIndex !== -1) {
          document.getElementById(`otp-${nextIndex}`).focus();
        }
      } else {
        newOtp[index] = value;
        setOtp(newOtp);
        const nextIndex = newOtp.findIndex((digit) => digit === "");
        if (nextIndex !== -1 && nextIndex > index) {
          document.getElementById(`otp-${nextIndex}`).focus();
        }
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const newOtp = [...otp];
      if (newOtp[index] === "" && index > 0) {
        document.getElementById(`otp-${index - 1}`).focus();
      }
      newOtp[index] = ""; // Clear the current box
      setOtp(newOtp);
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text");
    if (/^\d{6}$/.test(pastedData)) { // Adjust based on the OTP length
      const newOtp = pastedData.split("");
      setOtp(newOtp);
      // Optionally, move focus to the last filled input or another element after pasting
      document.getElementById(`otp-${newOtp.length - 1}`).focus();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (otp.every((digit) => digit !== "")) {
      console.log("OTP Submitted:", otp.join(""));
      const url = '/verify-otp';
      const getParams = {};
      const postParams = {
        otp: otp.join(""),
        device_model: "",
        device_token: "",
        device_type: "W",
        phone_code: userData?.phone_code,
        phone: userData?.phone,
        os_version: window.navigator.platform,
        is_verified: userData?.is_verified,
        app_version: ""
      }

      apiService.postRequest(url, getParams, postParams)
        .then((response) => {
          if (response.status === 200) {
            toast.success(response.message);
            const data = response.data;
            dispatch(setUserData(data));
            clearOtp(); // Clear OTP after submission
            resetTimer();
            doSetupPopupType(false);
          } else {
            toast.error(response.body.messagee);
          }
        })
        .catch((err) => {
          toast.error(err.message);

        });

      
    }
  };

  const handleBackClick = () => {
    clearOtp(); // Clear OTP when going back
    doSetupPopupType(true,'login');
  };

  const resetTimer = () => {
    setTimer(59);
    setIsTimerActive(true);
    clearOtp(); // Clear OTP when resetting the timer
  };

  useEffect(() => {
    if (isTimerActive && timer > 0) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(interval);
    } else if (timer === 0) {
      setIsTimerActive(false);
    }
  }, [isTimerActive, timer]);

  useEffect(() => {
    resetTimer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const images = [
    {
      id: 1,
      image: "/assets/images/login-banner.svg",
    },
    {
      id: 2,
      image: "/assets/images/login-banner-2.svg",
    }
  ]

  const handleResendOtp = () => {
    const url = '/resend-otp';
    const getParams = {};
    const postParams = {
      phone_code: userData?.data?.phone_code,
      phone: userData?.data?.phone,
    }

    apiService.postRequest(url, getParams, postParams)
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.message);
          resetTimer();
        } else {
          toast.error(response.body.messagee);
        }
      })
      .catch((err) => {
        toast.error(err.message);

      });
  }


  return (
    <div className="grid relative grid-cols-1 max-h-[calc(90vh-16px)] items-center lg:grid-cols-2 bg-white rounded-xl shadow-lg border overflow-hidden w-full  xs:max-w-[450px] lg:max-w-[1100px] mx-auto p-6 lg:p-3">
      <div className="hidden lg:block max-h-[calc(90vh-40px)] w-full min-h-[75vh] h-full bg-cover rounded-xl">
        <Swiper
          {...loginSliderConfig}
          modules={[Navigation, Pagination, Controller]}
          className='h-full'>
          {images.map((item) => (
            <SwiperSlide key={item.id}>
              <img src={item.image} alt="" className='w-full h-full object-cover rounded-xl' />
            </SwiperSlide>
          ))}
        </Swiper>

      </div>

      <div className='w-full relative mt-6 mb-16 lg:px-12 max-h-[calc(90vh-124px)] h-fit overflow-auto lg:pt-8 lg:pb-2 lg:w-11/12 mx-auto flex flex-col justify-between gap-8'>
        <div className="text-center">
          <p className="text-2xl md:text-3xl text-theme-headingColor pb-1 font-customBold">OTP Verification</p>
          <p className="text-theme-subHeadingColor text-sm font-customMedium">Please enter the OTP sent to your registered email or to verify your account.</p>
        </div>

        <form onSubmit={handleSubmit} className='flex flex-1 overflow-y-auto scroll-style flex-col gap-8'>
          <div>
            <div className="flex justify-center gap-2">
              {otp.map((digit, index) => (
                <input key={index} type="text" id={`otp-${index}`} value={digit} onChange={(e) => handleChange(e, index)} onKeyDown={(e) => handleKeyDown(e, index)}  onPaste={handlePaste} maxLength="1"
                  className="w-10 h-10 border border-gray-300 rounded-md flex items-center justify-center text-xl font-bold text-gray-700 bg-white text-center focus:outline-none focus:border-blue-500" />
              ))}
            </div>
            <p className="text-xs text-gray-500 mt-4 text-center">Resend a new code in{" "}<span className="font-semibold text-theme-accentColorSecondary">{timer > 0 ? `00:${String(timer).padStart(2, '0')}` : "00:00"}</span>{" "}sec</p>
            {timer === 0 && (
              <p className="text-xs text-theme-accentColorSecondary cursor-pointer underline text-center mt-2" onClick={handleResendOtp}>Reset OTP</p>
            )}
          </div>

          <div className="">
            <button type="submit" className="theme-button py-3 bg-theme-accentColorSecondary border-theme-accentColorSecondary">Submit</button>
          </div>
        </form>

      </div>
      
      <div className='absolute top-2 lg:top-4 grid grid-cols-1 lg:grid-cols-2 w-full '>
        <button onClick={handleBackClick} className="flex gap-2 lg:col-start-2 px-6 lg:px-12 items-center text-theme-headingColor hover:text-theme-accentColorSecondary">
          <img src="assets/icons/back-arrow.svg" alt="" className="cursor-pointer rtl:scale-x-[-1] size-3 lg:size-4" />
          <span className="text-sm lg:text-base font-medium">Back</span>
        </button>
      </div>
      
      <div className='text-center  absolute bottom-6 lg:bottom-3 grid grid-cols-1 lg:grid-cols-2 '>
        <p className="text-[13px] font-customMedium lg:col-start-2 px-6 lg:px-12 md:w-4/5 xl:w-3/4 mx-auto text-theme-subHeadingColor">By signing up, you agree to the{" "}<span className="text-theme-accentColorSecondary font-customBold">Terms of Service</span>{" "}and{" "}<span className="text-theme-accentColorSecondary font-semibold">Data Processing Agreement</span></p>
      </div>
    </div>
  );
};

export default OTPVerification;
