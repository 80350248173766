import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { setPopupStatus, setPopupType, setPopupData } from '../redux/slices/popupModal';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { loginSliderConfig } from '../constants/CarouselConfigs';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Controller, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import Select from "react-select";
import apiService from '../utility-functions/Api-Services';
import toast from "react-hot-toast";
import usePasswordVisibility from '../hooks/usePasswordVisibility';
import { fetchStateData, fetchDistrictData, fetchTalukaData } from '../redux/slices/addressHelper';
import { setUserData } from '../redux/slices/userDataSlice';

const Register = () => {
  const dispatch = useDispatch();
  const { passwordVisibility, togglePasswordVisibility } =
    usePasswordVisibility(['newPassword', 'confirmPassword']);

  function doSetupPopupType(status, type = null , data = null) {
    dispatch(setPopupType(type));
    dispatch(setPopupStatus(status));
    dispatch(setPopupData(data));
  }




  const handleNumericInput = (event) => {
    event.target.value = event.target.value.replace(/[^0-9]/g, '');
  };

  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const onSubmit = (data) => {
      const url = '/register';
      const getParams = {};
      const postParams = {
        first_name: data?.firstName,
        last_name: data?.lastName,
        email: data?.email,
        phone_code: selectedCountryCode?.code.replace('+', '') || '',
        phone: data?.phone,
        password: data?.password,
        device_type: "W",
        state_id: selectedState?.value || '',
        district_id: selectedDistrict?.value || '',
        taluka_id: selectedTaluka?.value || '',
        referral_code: "",
        device_model: "",
        device_token: "",
        app_version: "",
        os_version: window.navigator.platform,

      };
      apiService.postRequest(url, getParams, postParams)
        .then((response) => {
          if (response.status === 200) {
            toast.success(response.message);
            const data = response.data;
           
            reset();
            if(data?.is_verified === 0){
              doSetupPopupType(true,'otpVerification', data);
            }
            // no need for this just for temp purposes
            else{ 
              dispatch(setUserData(data));
            }
          } else {
            toast.error(response.message);
          }
        })
        .catch((err) => {
          toast.error(err.message);

        });
  };


  const errorMsgClass = 'text-red-600 text-xs ps-1 font-customRegular';



  const [stateData, setStateData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [talukaData, setTalukaData] = useState([]);
  useEffect(() => {
    fetchStateData().then((data) => setStateData(data));
  }, []);

  const [selectedState, setSelectedState] = useState(null)
   const handleStateChange = (selectedOption) => {
    setSelectedState(selectedOption);
    setSelectedDistrict(null); 
    setSelectedTaluka(null); 
    fetchDistrictData(selectedOption.value).then((data) => setDistrictData(data));
   
  };
  
  const [selectedDistrict, setSelectedDistrict] = useState(null)
 const handleDistrictChange = (selectedOption) => {
    setSelectedDistrict(selectedOption);
    setSelectedTaluka(null); 
    fetchTalukaData(selectedOption.value).then((data) => setTalukaData(data));
   
  };
   const [selectedTaluka, setSelectedTaluka] = useState(null)
    const handleTalukaChange = (selectedOption) => {
    setSelectedTaluka(selectedOption);
  };
  



  const images = [
    {
      id: 1,
      image: "/assets/images/login-banner.svg",
    },
    {
      id: 2,
      image: "/assets/images/login-banner-2.svg",
    }
  ]

  const countryCodes = [
    { code: "+91", flag: "/assets/icons/ind-flag-img.svg", country: "India" },
    { code: "+1", flag: "/assets/icons/us-flag-img.svg", country: "USA" },
  ];

  const [selectedCountryCode, setSelectedCountryCode] = useState(
    countryCodes[0]
  );
  const handleCountryChange = (selectedOption) => {
    setSelectedCountryCode(selectedOption);

  };
  const formatOptionLabel = ({ code, flag, country }) => (
    <div className="flex items-center gap-1">
      <img src={flag} alt={country} className="size-4" />
      <span className='text-sm md:text-[15px]'>{code}</span>
    </div>
  );

  return (
    <div className="grid relative grid-cols-1 max-h-[calc(90vh-16px)] lg:grid-cols-2 bg-white rounded-xl shadow-lg border overflow-hidden w-full  xs:max-w-[450px] lg:max-w-[1100px] mx-auto p-6 lg:p-3">
      <div className="hidden lg:block max-h-[calc(90vh-40px)] w-full min-h-[75vh] h-full bg-cover rounded-xl">
        <Swiper
          {...loginSliderConfig}
          modules={[Navigation, Pagination, Controller]}
          className="h-full"
        >
          {images.map((item) => (
            <SwiperSlide key={item.id}>
              <img src={item.image} alt="" className="w-full h-full object-cover rounded-xl" />
            </SwiperSlide>
          ))}
        </Swiper>

      </div>

      <div className="w-full mb-16 lg:px-12 max-h-[calc(90vh-104px)] h-fit overflow-auto lg:pt-8 lg:pb-2 lg:w-11/12 mx-auto flex flex-col justify-between gap-8">
        <div className=' text-center'>
          <p className="text-2xl md:text-3xl text-theme-headingColor pb-1 font-customBold">Create an Account</p>
          <p className='text-theme-subHeadingColor text-sm font-customMedium'>Already have an account? <span onClick={() => doSetupPopupType(true, 'login')} className='font-customBold text-theme-accentColorSecondary underline cursor-pointer'>Log In</span></p>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-1 overflow-y-auto scroll-style flex-col gap-8'>
          <div className='flex flex-col gap-4 flex-1 overflow-y-auto scroll-style '>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <input
                  type="text"
                  placeholder='First name'
                  {...register('firstName', {
                    required: "Please enter your first name"
                  })}
                  className='input-box'
                />
                {errors.firstName && (
                  <small className={errorMsgClass}>{errors.firstName.message}</small>
                )}
              </div>

              <div>
                <input
                  type="text"
                  placeholder='Last name'
                  {...register('lastName', {
                    required: "Please enter your last name"
                  })}
                  className='input-box'
                />
                {errors.lastName && (
                  <small className={errorMsgClass}>{errors.lastName.message}</small>
                )}
              </div>
            </div>

            <div>
              <input
                type="email"
                inputMode='email'
                placeholder='Email'
                {...register('email', {
                  required: "Please enter your email address",
                  pattern: {
                    value: /^\S+@\S+\.\S+$/i,
                    message: "Invalid email address",
                  }
                })}
                className='input-box'
              />
              {errors.email && (
                <small className={errorMsgClass}>{errors.email.message}</small>
              )}
            </div>

            <div>

              <div className='input-box col-span-2 lg:col-span-1 p-0  flex'>
                <Select
                  menuPlacement="top"
                  value={selectedCountryCode}
                  onChange={handleCountryChange}
                  options={countryCodes}
                  // styles={customStyles}
                  formatOptionLabel={formatOptionLabel}
                  className="country-select px-2 pe-4 w-[75px] max-w-[75px] text-sm outline-none !border-none"
                />
                <input type="tel" placeholder="Phone Number" onInput={handleNumericInput}
                  {...register("phone", {
                    required: "Phone number is required",
                    minLength: { value: 10, message: "Phone number must be at least 10 digits" },
                    maxLength: { value: 10, message: "Phone number must be at most 10 digits" }
                  })} className="input-box !border-s rounded-s-none px-3 border-0 focus:outline-none" />
              </div>
              {errors.phone && (
                <small className={errorMsgClass}>{errors.phone.message}</small>
              )}
            </div>

            <div>
              <div className='relative'>
                <input
                  type={passwordVisibility.newPassword.isVisible ? 'text' : 'password'}
                  placeholder='Set password'
                  {...register('password', {
                    required: "Please set a password before proceeding further",
                    minLength: {
                      value: 6,
                      message: "Password must be at least 6 characters long",
                    }
                  })}
                  className='input-box'
                />
                <img
                  src={passwordVisibility.newPassword.icon}
                  alt="password"
                  onClick={() => togglePasswordVisibility('newPassword')}
                  className="password-icon"
                />
              </div>
              {errors.password && (
                <small className={errorMsgClass}>{errors.password.message}</small>
              )}
            </div>


            <div>
              <Select
                menuPlacement="top"
                value={selectedState}
                onChange={handleStateChange}
                options={stateData?.map((state) => ({ value: state.state_id, label: state.name,districtLength : state?.has_districts ?? 0  }))}
                className="input-box px-0 py-0 border-0 custom-select"
              />
            </div>
            
            {selectedState?.districtLength > 0 && (
               <div>
               <Select
                 menuPlacement="top"
                 value={selectedDistrict}
                 onChange={handleDistrictChange}
                 options={districtData?.map((state) => ({ value: state.district_id, label: state.name,talukaLength : state?.has_talukas ?? 0 }))}
                 className="input-box px-0 py-0 border-0 custom-select"
               />
             </div>
            )}
           
            
           {selectedState?.districtLength > 0 && selectedDistrict?.talukaLength > 0  && (
            <div>
              <Select
                menuPlacement="top"
                value={selectedTaluka}
                onChange={handleTalukaChange}
                options={talukaData.map((state) => ({ value: state.district_id, label: state.name }))}
                className="input-box px-0 py-0 border-0 custom-select"
              />
            </div>
           )}


          </div>



          <button
            type="submit"
            className="theme-button py-3 bg-theme-accentColorSecondary border-theme-accentColorSecondary" >
            Next
          </button>

        </form>


      </div>

      <div className='text-center  absolute bottom-6 lg:bottom-3 grid grid-cols-1 lg:grid-cols-2 '>
        <p className='text-[13px] font-customMedium lg:col-start-2 px-6 lg:px-12 md:w-4/5 xl:w-3/4 mx-auto text-theme-subHeadingColor'>By signing up, you agree to the <span className='text-theme-accentColorSecondary font-customBold'><Link to="/">Terms of Service</Link></span> and <span className='text-theme-accentColorSecondary font-semibold'><Link to="/">Data Processing Agreement</Link></span></p>
      </div>
    </div>
  );
};

export default Register;
